<template>
  <div
    ref="$searchMenuBar"
    class="search-menu-bar flex md:w-full md:h-full justify-between"
  >
    <div
      class="search-menu-bar__input md:max-w-[42.75rem] w-full mr-4 md:m-auto flex border-b border-black p-2"
    >
      <IconSearch class="search-menu-bar__input-icon stroke-dark-grey mr-2" />
      <input
        v-model="searchValue"
        type="text"
        class="search-menu-bar__input-item w-full text-dark-grey focus:outline-none"
        :placeholder="$t('search.placeholder')"
        @input="onInput"
        @keyup.enter="onEnter"
      />
    </div>
    <div class="search-menu-bar__close flex">
      <button class="m-auto" @click="onClose">
        <IconClose class="w-6 h-6" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconSearch } from "@/components/atoms/Icon";
import { ref, defineEmits } from "vue";
import { IconClose } from "@/components/atoms/Icon";

const emits = defineEmits(["close", "changeValue", "enterKeyUp"]);

const searchValue = ref<string>("");

const onInput = () => {
  emits("changeValue", searchValue.value);
};

const onEnter = () => {
  emits("enterKeyUp", searchValue.value);
  onClose();
};

const onClose = () => {
  emits("close");
  clearSearchValue();
};

const clearSearchValue = () => {
  searchValue.value = "";
};

defineExpose({ clearSearch: clearSearchValue });
</script>
